<template>
  <div class="w-full md:mx-2 rounded">
    <div class="mb-10 bg-white">
      <div
        class="flex justify-between border-b border-gray-300 mb-3 items-center px-2"
      >
        <p class="p-3 font-desain text-[#212121] font-semibold text-lg">
          List Request Webinar Plus
        </p>
      </div>
      <div class="w-full m-auto">
        <DataTable
          :value="requestWebinar"
          :rows="10"
          dataKey="id"
          :rowsPerPageOptions="[10, 25, 50]"
          class="p-datatable-sm font-solusi text-sm"
          responsiveLayout="scroll"
          :lazy="true"
          :rowHover="true"
          :loading="isLoading"
          @rowExpand="onRowExpand"
          v-model:expandedRows="selectedWebinarRequest"
        >
          <template #empty>
            <div class="w-full flex flex-col items-center justify-center py-8">
              <img
                src="/assets/images/modal/ilustration-webinar.svg"
                class="w-3/4 md:w-1/3 m-auto"
                alt=""
              />
              <div
                class="text-center font-semibold text-accent font-solusi text-base mt-2"
              >
                Tidak ada daftar webinar yang ditampilkan
              </div>
            </div>
          </template>
          <template #loading>
            <div class="text-center font-semibold font-solusi text-base">
              Loading data webinar. Please wait.
            </div>
          </template>
          <Column
            field="name"
            header="No."
            headerClass="w-12 text-[#212121]"
            bodyClass="font-solusi font-semibold text-sm text-[#383838]"
          >
            <template #body="{data}"> {{ data.no }}. </template>
          </Column>
          <Column
            field="name"
            header="Webinar"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ data.name }}
            </template>
          </Column>
          <Column :expander="true" headerStyle="width: 3rem" header="Peserta" />
          <Column
            field="name"
            header="Aksi"
            headerClass="w-32 md:w-44"
            bodyClass="font-solusi font-medium text-sm flex w-auto"
          >
            <template #body="{data}">
              <button
                v-tooltip.top="'Terima Request Webinar'"
                @click="showModalApproveWebinar(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-accept mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#2FBD71" />
                  <g style="mix-blend-mode:lighten">
                    <path
                      d="M6.18277 11.9412C6.3659 12.129 6.62072 12.2355 6.88703 12.2355C6.88961 12.2355 6.89232 12.2355 6.89516 12.2355C7.16457 12.2332 7.42081 12.1225 7.60252 11.9295L12.9232 6.27858C13.2839 5.89547 13.2558 5.30134 12.8609 4.95154C12.4658 4.602 11.8531 4.62893 11.4924 5.01191L6.87501 9.91578L5.43769 8.43984C5.07013 8.06261 4.45692 8.04583 4.06818 8.40189C3.67917 8.75807 3.66187 9.35246 4.02904 9.72968L6.18277 11.9412Z"
                      fill="white"
                    />
                  </g>
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Terima</span
                >
              </button>
              <button
                v-tooltip.top="'Tolak Request Webinar'"
                @click="showRejectRequestWebinar(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-reject mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM5.86569 4.23431C5.55327 3.9219 5.04673 3.9219 4.73431 4.23431C4.4219 4.54673 4.4219 5.05327 4.73431 5.36569L7.36863 8L4.73431 10.6343C4.4219 10.9467 4.4219 11.4533 4.73431 11.7657C5.04673 12.0781 5.55327 12.0781 5.86569 11.7657L8.5 9.13137L11.1343 11.7657C11.4467 12.0781 11.9533 12.0781 12.2657 11.7657C12.5781 11.4533 12.5781 10.9467 12.2657 10.6343L9.63137 8L12.2657 5.36569C12.5781 5.05327 12.5781 4.54673 12.2657 4.23431C11.9533 3.9219 11.4467 3.9219 11.1343 4.23431L8.5 6.86863L5.86569 4.23431Z"
                    fill="currentColor"
                  />
                  <g style="mix-blend-mode:lighten">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM5.86569 4.23431C5.55327 3.9219 5.04673 3.9219 4.73431 4.23431C4.42189 4.54673 4.42189 5.05327 4.73431 5.36569L7.36863 8L4.73431 10.6343C4.42189 10.9467 4.42189 11.4533 4.73431 11.7657C5.04673 12.0781 5.55327 12.0781 5.86569 11.7657L8.5 9.13137L11.1343 11.7657C11.4467 12.0781 11.9533 12.0781 12.2657 11.7657C12.5781 11.4533 12.5781 10.9467 12.2657 10.6343L9.63137 8L12.2657 5.36569C12.5781 5.05327 12.5781 4.54673 12.2657 4.23431C11.9533 3.9219 11.4467 3.9219 11.1343 4.23431L8.5 6.86863L5.86569 4.23431Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Tolak</span
                >
              </button>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div v-if="loadingUserRequestWebinar">
              <p
                class="text-center py-3 font-bold font-solusi text-sm text-blue-500"
              >
                Sedang memuat data...
              </p>
            </div>
            <div v-else>
              <div v-if="userRequestWebinar.length > 0">
                <DataTable
                  :value="userRequestWebinar"
                  responsiveLayout="scroll"
                  :paginator="true"
                  :rows="5"
                  dataKey="id"
                  :rowHover="true"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  :rowsPerPageOptions="[5, 10, 25, 50]"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  class="p-datatable-sm font-solusi text-sm"
                  showGridlines
                >
                  <template #header>
                    <div class="flex items-center justify-between">
                      <p class="font-solusi text-base font-semibold">
                        {{ slotProps.data.name }}
                      </p>
                    </div>
                  </template>
                  <Column
                    field="name"
                    header="No."
                    headerClass="w-12 text-[#212121]"
                    bodyClass="font-solusi text-sm text-[#383838]"
                  >
                    <template #body="{data}"> {{ data.no }}. </template>
                  </Column>
                  <Column
                    field="user.name"
                    header="Member"
                    sortable
                    bodyClass="font-solusi font-medium text-sm text-[#383838]"
                  >
                    <template #body="{data}">
                      {{ data.user.name }}
                    </template>
                  </Column>
                  <Column
                    field="reason"
                    header="Alasan"
                    sortable
                    bodyClass="font-solusi font-medium text-sm text-[#383838]"
                  >
                    <template #body="{data}">
                      {{ data.reason }}
                    </template>
                  </Column>
                </DataTable>
              </div>
              <div v-else>
                <p
                  class="text-center py-3 font-semibold text-red-500 font-solusi text-sm"
                >
                  Belum ada member yang mengajukan
                </p>
              </div>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showModalRequestWebinar"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showModalRequestWebinar"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51 scroll-custom"
      >
        <div class="relative w-full md:w-[40%] my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <h3 class="text-xl font-semibold text-[#212121] font-desain">
                Request Webinar Plus
              </h3>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalRequestWebinar()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div class="px-6 pt-3 flex-auto overflow-x-hiden overflow-y-auto">
              <div
                class="bg-[#FFFAF1] rounded-md p-3 flex flex-col items-center justify-center mb-3"
              >
                <p class="font-solusi text-[#757575] text-sm mb-1">
                  Tanggal pengajuan webinar plus member:
                </p>
                <p class="font-solusi text-[#333333] text-sm font-semibold">
                  {{ formatDate(selectedWebinar?.request_plus_started_at) }} s/d
                  {{ formatDate(selectedWebinar?.request_plus_expired_at) }}
                </p>
              </div>
              <div class="mb-1">
                <label
                  for="date"
                  class="font-solusi text-[#212121] block text-sm font-medium mb-1"
                  >Pilih tanggal</label
                >
                <div class="custom-calendar">
                  <v-calendar
                    is-expanded
                    paneCentered
                    @dayclick="changeDate"
                    :min-date="new Date()"
                    :attributes="attributesRequest"
                    class="font-solusi"
                  />
                </div>
              </div>
              <div class="mb-1 grid grid-cols-1 md:grid-cols-2 gap-3">
                <div class="col-span-1">
                  <label class="font-solusi text-sm mb-1">
                    Jam mulai
                  </label>
                  <v-date-picker
                    v-model="formWebinarPlus.started_at"
                    mode="time"
                    is24hr
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="font-solusi text-sm px-3 py-2 rounded focus:outline-none w-full"
                        :class="
                          errorDeadline ? 'border-2 border-red-500' : 'border'
                        "
                        placeholder="Mulai"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>
                <div class="col-span-1">
                  <label class="font-solusi text-sm mb-1">
                    Jam berakhir
                  </label>
                  <v-date-picker
                    v-model="formWebinarPlus.expired_at"
                    mode="time"
                    is24hr
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="font-solusi text-sm px-3 py-2 rounded focus:outline-none w-full"
                        :class="
                          errorDeadline ? 'border-2 border-red-500' : 'border'
                        "
                        placeholder="Berakhir"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div v-if="checkErrorHours" class="block">
                <p class="text-xs text-[#DE1306] font-solusi font-semibold">
                  Jam berakhir harus lebih besar dari jam mulai
                </p>
              </div>
            </div>
            <div class="flex justify-center p-3">
              <button
                class="px-16 py-1 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none"
                :class="
                  checkForm && !checkErrorHours
                    ? 'bg-[#00446F] text-white'
                    : 'border border-gray-100 text-[#9E9E9E] cursor-not-allowed'
                "
                @click="approveWebinar"
                :disabled="!checkForm || checkErrorHours"
              >
                Buat Webinar
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="showRejectWebinarPlus"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showRejectWebinarPlus"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeRejectWebinarPlus"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-end p-3 pb-0 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeRejectWebinarPlus()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
            >
              <img
                src="/assets/images/modal/illustration-reject-webinar.svg"
                class="w-1/2 m-auto"
                alt=""
              />
              <p
                class="font-desain font-semibold my-3 text-center text-[#212121] text-xl"
              >
                Tolak Webinar
              </p>
              <p
                class="font-solusi font-medium text-base text-center text-[#383838]"
              >
                Apakah Anda yakin ingin menolak webinar
                <span class="font-solusi font-semibold text-base"
                  >'{{ selectedWebinar?.name }}'</span
                >?
              </p>
              <div class="flex justify-evenly mt-8">
                <button
                  @click="closeRejectWebinarPlus"
                  class="bg-[#00446F] px-9 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
                >
                  Tidak
                </button>
                <button
                  @click="rejectRequestWebinar"
                  class="px-9 py-2 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
                >
                  Ya, saya yakin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showAlertRequestWebinar"
        class="opacity-50 fixed inset-0 z-51 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showAlertRequestWebinar"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeAlertRequestWebinar"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-end p-3 pb-0 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeAlertRequestWebinar()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
            >
              <img
                src="/assets/images/modal/illustration-alert.svg"
                class="w-1/2 m-auto"
                alt=""
              />
              <p
                class="font-desain font-semibold mt-3 mb-2 text-center text-[#212121] text-xl"
              >
                Maaf,
              </p>
              <p
                class="font-solusi font-medium text-sm text-center text-[#383838] md:px-2"
              >
                Anda hanya bisa melakukan terima/tolak webinar pada saat waktu
                pengajuan webinar plus member berakhir.
              </p>
              <div class="flex justify-evenly mt-8">
                <button
                  @click="closeAlertRequestWebinar"
                  class="bg-[#00446F] px-20 py-1.5 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Request Webinar Plus',
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      showDetailTask: false,
      showModalRequestWebinar: false,
      showRejectWebinarPlus: false,
      formWebinarPlus: {
        started_at: '' as string,
        expired_at: '' as string,
      },
      dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
      taskId: 0 as number,
      task: {} as any,
      errorDeadline: false,
      requestWebinar: [] as any,
      selectedWebinar: {} as any,
      userRequestWebinar: [] as any,
      showAlertRequestWebinar: false,
      attributesRequest: [] as any,
      selectedWebinarRequest: [] as any,
      loadingUserRequestWebinar: false,
    }
  },
  mounted() {
    this.getRequestWebinar()
  },
  computed: {
    checkForm() {
      if (this.formWebinarPlus.started_at && this.formWebinarPlus.expired_at) {
        return true
      } else {
        return false
      }
    },
    checkErrorHours() {
      if (this.formWebinarPlus.started_at && this.formWebinarPlus.expired_at) {
        const start = new Date(this.formWebinarPlus.started_at)
        const end = new Date(this.formWebinarPlus.expired_at)
        if (start.getTime() >= end.getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
  methods: {
    async getRequestWebinar() {
      this.isLoading = true
      await ClassroomModel.getRequestWebinarPlus(this.classroomCode)
        .then(res => {
          this.isLoading = false
          this.requestWebinar = []
          if (res.data.length > 0) {
            res.data.forEach((el: any, i: number) => {
              this.requestWebinar.push({
                ...el,
                no: i + 1,
              })
            })
          }
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
        })
    },
    async onRowExpand(event: any) {
      // console.log(event)
      this.selectedWebinarRequest = this.selectedWebinarRequest.filter(
        (el: any) => el.id == event.data.id
      )
      this.loadingUserRequestWebinar = true
      this.userRequestWebinar = []
      await ClassroomModel.getUserRequestWebinarPlus(event.data.id)
        .then(res => {
          this.loadingUserRequestWebinar = false
          res.data.forEach((el: any, i: number) => {
            this.userRequestWebinar.push({
              ...el,
              no: i + 1,
            })
          })
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          this.loadingUserRequestWebinar = false
        })
      // console.log(this.userRequestWebinar)
    },
    changeDate(event: any) {
      this.formWebinarPlus.started_at = moment(event.id).format(
        'YYYY-MM-DD HH:mm:00'
      )
      this.formWebinarPlus.expired_at = moment(event.id).format(
        'YYYY-MM-DD HH:mm:00'
      )
      const attrb = []
      const payload = {
        key: 1,
        dates: new Date(event.id),
        highlight: true,
      }
      attrb.push(payload)
      this.attributesRequest = attrb
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm:00')
    },
    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    showModalApproveWebinar(data: any) {
      this.attributesRequest = []
      const payload = {
        key: 1,
        dates: new Date(),
        highlight: true,
      }
      this.attributesRequest.push(payload)
      this.formWebinarPlus.started_at = ''
      this.formWebinarPlus.expired_at = ''
      this.selectedWebinar = data
      this.errorDeadline = false
      this.showModalRequestWebinar = true
    },
    closeModalRequestWebinar() {
      this.selectedWebinar = {}
      this.formWebinarPlus.started_at = ''
      this.formWebinarPlus.expired_at = ''
      this.errorDeadline = false
      this.showModalRequestWebinar = false
    },
    async approveWebinar() {
      if (
        this.timeZone(this.selectedWebinar.request_plus_expired_at) >=
        this.dateNow
      ) {
        this.showAlertRequestWebinar = true
      } else {
        if (this.checkForm) {
          const payload = {
            webinar_id: parseInt(this.selectedWebinar.id),
            started_at: moment(this.formWebinarPlus.started_at).format(
              'YYYY-MM-DD HH:mm:00'
            ),
            expired_at: moment(this.formWebinarPlus.expired_at).format(
              'YYYY-MM-DD HH:mm:00'
            ),
          }

          await ClassroomModel.approveRequestWebinar(this.selectedWebinar.id)
            .then(async res => {
              if (res.success) {
                await ClassroomModel.requestWebinarPlusMentor(payload)
                  .then(res => {
                    if (res.success) {
                      this.toast.success(
                        'Berhasil mengajukan webinar plus, silahkan menunggu konfirmasi atau hubungi Admin'
                      )
                      this.closeModalRequestWebinar()
                      this.getRequestWebinar()
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      }
    },
    showRejectRequestWebinar(data: any) {
      this.selectedWebinar = data
      this.showRejectWebinarPlus = true
    },
    closeRejectWebinarPlus() {
      this.selectedWebinar = {}
      this.showRejectWebinarPlus = false
    },
    async rejectRequestWebinar() {
      if (
        this.timeZone(this.selectedWebinar.request_plus_expired_at) >=
        this.dateNow
      ) {
        this.closeRejectWebinarPlus()
        this.showAlertRequestWebinar = true
      } else {
        await ClassroomModel.rejectRequestWebinar(this.selectedWebinar.id)
          .then(async res => {
            await this.getRequestWebinar()
            this.closeRejectWebinarPlus()
            this.toast.success('Berhasil menolak request webinar')
          })
          .catch(err => {
            this.toast.error(err.response.data.errors[0].message)
          })
      }
    },
    closeAlertRequestWebinar() {
      this.showAlertRequestWebinar = false
    },
  },
})
</script>

<style scoped>
::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}

.max-h-85 {
  max-height: 85vh;
}

.h-fit-content {
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}

.border-dark-blue {
  border: 1px solid #00446f;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  border: 1px solid #009dff;
  color: #009dff;
  font-weight: 600;
  background-color: #fff;
}
.btn-accept {
  color: #2fbd71;
  border: 1px solid #2fbd71;
}
.btn-reject {
  color: #de1306;
  border: 1px solid #de1306;
}
.btn-accept:hover,
.btn-reject:hover {
  color: #fff;
  transition-duration: 500ms;
}
.btn-accept:hover {
  background-color: #2fbd71;
}
.btn-reject:hover {
  background-color: #de1306;
}
::v-deep(.custom-calendar .vc-container) {
  border-radius: 0.5rem;
  width: 100%;
}
::v-deep(.custom-calendar .vc-container .vc-header) {
  padding: 10px 16px;
}
::v-deep(.custom-calendar .vc-pane) {
  border-radius: 5px;
}
::v-deep(.custom-calendar .vc-title) {
  font-family: 'SolusiSans', sans-serif;
}
::v-deep(.custom-calendar .vc-day-content) {
  font-family: 'SolusiSans', sans-serif;
}
::v-deep(.custom-calendar .vc-title) {
  color: #383838;
  font-weight: 600;
  font-size: 1rem;
}
::v-deep(.custom-calendar .vc-container .vc-weeks) {
  padding: 0;
}
::v-deep(.custom-calendar .vc-container .vc-weekday) {
  background-color: #f6fbff;
  color: #000;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}
::v-deep(.custom-calendar .in-next-month.is-not-in-month) {
  display: none;
}
::v-deep(.custom-calendar .vc-container .vc-day .cal-day-label) {
  color: #757575;
}
::v-deep(.custom-calendar .vc-container .on-left .vc-day-content) {
  color: #de1306;
}
::v-deep(.custom-calendar .vc-container .vc-weekday:first-child) {
  color: #de1306;
}
::v-deep(.custom-calendar .vc-container .is-today .cal-day-label) {
  width: fit-content;
  color: #00446f;
  font-weight: 600;
}
::v-deep(.custom-calendar .vc-arrows-container .vc-arrow) {
  color: #009dff;
}
</style>
