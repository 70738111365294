
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Request Webinar Plus',
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      showDetailTask: false,
      showModalRequestWebinar: false,
      showRejectWebinarPlus: false,
      formWebinarPlus: {
        started_at: '' as string,
        expired_at: '' as string,
      },
      dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
      taskId: 0 as number,
      task: {} as any,
      errorDeadline: false,
      requestWebinar: [] as any,
      selectedWebinar: {} as any,
      userRequestWebinar: [] as any,
      showAlertRequestWebinar: false,
      attributesRequest: [] as any,
      selectedWebinarRequest: [] as any,
      loadingUserRequestWebinar: false,
    }
  },
  mounted() {
    this.getRequestWebinar()
  },
  computed: {
    checkForm() {
      if (this.formWebinarPlus.started_at && this.formWebinarPlus.expired_at) {
        return true
      } else {
        return false
      }
    },
    checkErrorHours() {
      if (this.formWebinarPlus.started_at && this.formWebinarPlus.expired_at) {
        const start = new Date(this.formWebinarPlus.started_at)
        const end = new Date(this.formWebinarPlus.expired_at)
        if (start.getTime() >= end.getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
  methods: {
    async getRequestWebinar() {
      this.isLoading = true
      await ClassroomModel.getRequestWebinarPlus(this.classroomCode)
        .then(res => {
          this.isLoading = false
          this.requestWebinar = []
          if (res.data.length > 0) {
            res.data.forEach((el: any, i: number) => {
              this.requestWebinar.push({
                ...el,
                no: i + 1,
              })
            })
          }
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
        })
    },
    async onRowExpand(event: any) {
      // console.log(event)
      this.selectedWebinarRequest = this.selectedWebinarRequest.filter(
        (el: any) => el.id == event.data.id
      )
      this.loadingUserRequestWebinar = true
      this.userRequestWebinar = []
      await ClassroomModel.getUserRequestWebinarPlus(event.data.id)
        .then(res => {
          this.loadingUserRequestWebinar = false
          res.data.forEach((el: any, i: number) => {
            this.userRequestWebinar.push({
              ...el,
              no: i + 1,
            })
          })
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          this.loadingUserRequestWebinar = false
        })
      // console.log(this.userRequestWebinar)
    },
    changeDate(event: any) {
      this.formWebinarPlus.started_at = moment(event.id).format(
        'YYYY-MM-DD HH:mm:00'
      )
      this.formWebinarPlus.expired_at = moment(event.id).format(
        'YYYY-MM-DD HH:mm:00'
      )
      const attrb = []
      const payload = {
        key: 1,
        dates: new Date(event.id),
        highlight: true,
      }
      attrb.push(payload)
      this.attributesRequest = attrb
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm:00')
    },
    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    showModalApproveWebinar(data: any) {
      this.attributesRequest = []
      const payload = {
        key: 1,
        dates: new Date(),
        highlight: true,
      }
      this.attributesRequest.push(payload)
      this.formWebinarPlus.started_at = ''
      this.formWebinarPlus.expired_at = ''
      this.selectedWebinar = data
      this.errorDeadline = false
      this.showModalRequestWebinar = true
    },
    closeModalRequestWebinar() {
      this.selectedWebinar = {}
      this.formWebinarPlus.started_at = ''
      this.formWebinarPlus.expired_at = ''
      this.errorDeadline = false
      this.showModalRequestWebinar = false
    },
    async approveWebinar() {
      if (
        this.timeZone(this.selectedWebinar.request_plus_expired_at) >=
        this.dateNow
      ) {
        this.showAlertRequestWebinar = true
      } else {
        if (this.checkForm) {
          const payload = {
            webinar_id: parseInt(this.selectedWebinar.id),
            started_at: moment(this.formWebinarPlus.started_at).format(
              'YYYY-MM-DD HH:mm:00'
            ),
            expired_at: moment(this.formWebinarPlus.expired_at).format(
              'YYYY-MM-DD HH:mm:00'
            ),
          }

          await ClassroomModel.approveRequestWebinar(this.selectedWebinar.id)
            .then(async res => {
              if (res.success) {
                await ClassroomModel.requestWebinarPlusMentor(payload)
                  .then(res => {
                    if (res.success) {
                      this.toast.success(
                        'Berhasil mengajukan webinar plus, silahkan menunggu konfirmasi atau hubungi Admin'
                      )
                      this.closeModalRequestWebinar()
                      this.getRequestWebinar()
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      }
    },
    showRejectRequestWebinar(data: any) {
      this.selectedWebinar = data
      this.showRejectWebinarPlus = true
    },
    closeRejectWebinarPlus() {
      this.selectedWebinar = {}
      this.showRejectWebinarPlus = false
    },
    async rejectRequestWebinar() {
      if (
        this.timeZone(this.selectedWebinar.request_plus_expired_at) >=
        this.dateNow
      ) {
        this.closeRejectWebinarPlus()
        this.showAlertRequestWebinar = true
      } else {
        await ClassroomModel.rejectRequestWebinar(this.selectedWebinar.id)
          .then(async res => {
            await this.getRequestWebinar()
            this.closeRejectWebinarPlus()
            this.toast.success('Berhasil menolak request webinar')
          })
          .catch(err => {
            this.toast.error(err.response.data.errors[0].message)
          })
      }
    },
    closeAlertRequestWebinar() {
      this.showAlertRequestWebinar = false
    },
  },
})
